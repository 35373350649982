// @flow

import React from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Link from "gatsby-link";

import { Layout } from "@containers";

const ForbiddenPage = (props: *) => (
    <Layout location={props.location}>
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="100%"
        >
            <h1 style={{ marginBottom: "2rem" }}>
                U heeft geen toegang tot deze pagina.
            </h1>
            <Link to="/" id="link-to-startpage">
                <Button
                    id="lnkButton"
                    color="primary"
                    variant="outlined"
                    size="medium"
                    onClick={() => undefined}
                >
                    Naar startpagina
                </Button>
            </Link>
        </Box>
    </Layout>
);

export default ForbiddenPage;
